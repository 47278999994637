<template>
  <div class="tw-mb-2 tw-flex tw-flex-col tw-gap-2">
    <c-label v-if="label" :label />

    <div class="tw-flex tw-flex-wrap tw-gap-4">
      <div
        v-if="!hideList"
        v-for="(file, key) in model"
        class="attachment attachment--file tw-relative tw-flex tw-p-2"
      >
        <div class="tw-absolute -tw-right-2 -tw-top-4 tw-flex tw-gap-x-1">
          <button
            v-if="preview"
            class="file tw-flex tw-aspect-square tw-w-[25px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-100 tw-transition hover:tw-bg-primary-200"
            :id="`file-${key}`"
            :data-src="file.path"
            :data-download="file.name"
          >
            <q-icon name="fas fa-magnifying-glass" color="primary" />
          </button>

          <div
            v-if="!readonly || removable"
            class="tw-flex tw-aspect-square tw-w-[25px] tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-full tw-bg-red-100 tw-transition hover:tw-bg-red-200"
            @click="handleRemoveAttachment(file)"
          >
            <q-icon name="fas fa-trash" color="negative" />
          </div>
        </div>

        <AttachmentPreviewComponent :attachment="file" />
      </div>

      <label v-if="!readonly" class="attachment attachment--upload" for="input">
        <q-icon size="small" name="fa-duotone fa-paperclip" />

        <span>Bijlage toevoegen</span>
      </label>
    </div>

    <!--    <p class="tw-m-0 tw-text-xs tw-text-gray-700" v-if="!readonly">-->
    <!--      Toegestane bestandstypen:-->
    <!--      {{-->
    <!--        settingsAllowedFileTypes-->
    <!--          ? `${settingsAllowedFileTypes} en afbeeldingen`-->
    <!--          : "alleen afbeeldingen"-->
    <!--      }}-->
    <!--    </p>-->

    <p class="tw-m-0 tw-text-xs tw-text-gray-700" v-if="fileSizeLimit">
      Max. {{ fileSizeLimit }}MB per bestand
    </p>

    <input
      v-if="!readonly"
      class="tw-hidden"
      @change="handleInput"
      ref="file"
      type="file"
      name="input"
      id="input"
      :accept="allowedFileTypes()"
      :multiple
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { allowedFileTypes, invalidFileMessage } from "composables/useHelpers";
import AttachmentPreviewComponent from "components/global/AttachmentPreviewComponent.vue";
import { useQuasar } from "quasar";
import { useSettingsStore } from "store/modules/settingStore.js";
import CDialog from "components/wrappers/dialog/CDialog.vue";

import lightGallery from "lightgallery";
import "lightgallery/css/lightgallery.css";

// Model
const model = defineModel({
  type: Array,
  required: true,
  default: () => [],
});

// Settings store
// const settingsStore = useSettingsStore();
// const { allowedFileTypes: settingsAllowedFileTypes } = settingsStore;

// Emtis
const emit = defineEmits(["delete"]);

// Quasar
const $q = useQuasar();

// Props
const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  allowedFileTypes: {
    type: Array,
    default: () => {
      return allowedFileTypes();
    },
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  fileSizeLimit: {
    type: Number,
    required: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  removable: {
    type: Boolean,
    default: false,
  },
  hideList: {
    type: Boolean,
    default: false,
  },
});

// Refs
const file = ref(null);

// Methods
const handleInput = () => {
  for (const [key, value] of Object.entries(file.value.files)) {
    const filetype = value.type;

    if (!props.allowedFileTypes.includes(filetype)) {
      alert(invalidFileMessage);

      return delete file.value.files[key];
    }

    model.value.push(value);
  }

  // Reset the file input so changes will be reflected
  // Was an issue where one removed the file and added the same file again
  file.value.value = "";
};

const removeAttachment = (file) => {
  model.value = model.value.filter((value) => value !== file);
};

const handleRemoveAttachment = (file) => {
  $q.dialog({
    component: CDialog,
    componentProps: {
      title: "Bijlage verwijderen",
      message: "Weet je zeker dat je deze bijlage wilt verwijderen?",
      cancel: true,
      primaryLabel: "Bijlage verwijderen",
    },
  }).onOk(async () => {
    removeAttachment(file);
    emit("delete", file);
  });
};

onMounted(() => {
  if (!props.preview) {
    return;
  }

  const files = document.querySelectorAll(".file");

  files.forEach((file) => {
    lightGallery(file, {
      licenseKey: import.meta.env?.VITE_LIGHTGALLERY_LICENSE_KEY,
      selector: "this",
      enableDrag: false,
      counter: false,
    });
  });
});
</script>

<style lang="scss">
.attachment {
  @apply tw-flex tw-aspect-square tw-w-[100px] tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-text-balance tw-rounded tw-p-2 tw-text-center tw-text-xs tw-transition;

  &--upload {
    @apply tw-cursor-pointer tw-border tw-border-gray-300 tw-bg-primary-50 hover:tw-bg-primary-100;
  }

  &--file {
    @apply tw-select-none tw-border tw-border-primary-100;
  }
}
</style>
