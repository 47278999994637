<template>
  <q-radio v-bind="$attrs" :size type="radio" :class="classes">
    <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>

    <q-icon :size v-if="tooltip" name="fa-solid fa-info-circle">
      <q-tooltip anchor="top middle" self="bottom middle"
        >{{ tooltip }}
      </q-tooltip>
    </q-icon>

    <div class="custom-input__check" :class="feedbackIconClasses">
      <q-icon :name="feedbackIcon" class="custom-input__check__icon" />
    </div>
  </q-radio>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { debounce } from "quasar";

const props = defineProps({
  borderless: {
    type: Boolean,
    default: false,
  },
  feedback: {
    type: Object,
    default: {},
  },
  tooltip: {
    type: String,
    required: false,
    default: "",
  },
  size: {
    type: String,
    default: "xs",
  },
});

// Refs
const currentFeedback = ref({});

const classes = computed(() => {
  return [
    ...(!props.borderless ? ["border"] : []),
    ...(Object.keys(currentFeedback.value).length
      ? currentFeedback.value?.type === "error"
        ? ["feedback-error"]
        : ["feedback-success"]
      : []),
  ];
});

// Return the correct icon based on the feedbackType
const feedbackIcon = computed(() => {
  const icon = currentFeedback.value.type === "error" ? "fa-xmark" : "fa-check";

  return `fa-solid ${icon}`;
});

// Conditionally add classes based on the feedback
const feedbackIconClasses = computed(() => {
  return [
    ...(currentFeedback.value.type ? ["is-active"] : []),
    ...(currentFeedback.value.type === "error" ? ["tw-bg-red-500"] : []),
    ...(currentFeedback.value.type === "success" ? ["tw-bg-green-500"] : []),
  ];
});

// Remove the errors from the input
const removeFeedback = debounce(() => {
  if (currentFeedback.value.type === "error") {
    return;
  }

  currentFeedback.value = {};
}, 3000);

// Watch errors
watch(
  () => props.feedback,
  (feedback) => {
    currentFeedback.value = feedback;

    removeFeedback();
  },
);
</script>

<style lang="scss">
div.q-radio {
  display: flex !important;
  border: none;
  border-radius: $generic-border-radius;
  transition: border $generic-hover-transition;
  padding-right: 4px;
  position: relative;
  overflow: hidden;

  &.border {
    border: 1px solid $grey;
    transition: border $generic-hover-transition;

    &:hover {
      border: 1px solid $primary;
    }

    &.feedback-success {
      @apply tw-border-green-500;
    }

    &.feedback-error {
      @apply tw-border-red-500;
    }
  }

  &.feedback-error {
    @apply tw-bg-red-50;

    .q-radio__inner {
      @apply tw-text-red-500;
    }
  }

  &.feedback-success {
    @apply tw-bg-green-50;

    .q-radio__inner {
      @apply tw-text-green-500;
    }
  }

  &.disabled {
    &.border:hover {
      border-color: inherit !important;
    }
  }

  &:has(.q-radio__label:not(:empty)) {
    display: flex;
    padding-right: 8px;
  }

  .q-radio__inner {
    @apply tw-transition-all tw-duration-200;
  }

  .q-radio__label:has(.q-icon) {
    @apply tw-flex tw-flex-1 tw-items-center tw-justify-between;
  }

  .q-radio__label {
    @apply tw-whitespace-nowrap;
  }

  .custom-input__check {
    @apply tw-flex tw-items-center tw-justify-center tw-rounded-bl tw-transition-all tw-duration-200;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(102%, -102%, 0);

    &.is-active {
      transform: translate3d(0, 0, 0);
    }

    &__icon {
      @apply tw-text-white;
    }
  }
}
</style>
