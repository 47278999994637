import useHelpers from "composables/useHelpers.js";
import { useModuleStore } from "store/modules/moduleStore.js";
import { storeToRefs } from "pinia";

const { mappedTerms } = useHelpers();

const routes = [
  {
    path: "/overview",
    name: "overview",
    redirect: () => {
      const moduleStore = useModuleStore();
      const { redirectToCorrectModule } = storeToRefs(moduleStore);
      return { name: redirectToCorrectModule.value.overviewRoute };
    },
    component: () => import("views/EmptyView.vue"),
    meta: {
      title: "Overzichten",
      roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
      settingRequired: ["studentMeeting", "learningObjectives"],
      icon: "fa-rectangle-history-circle-plus",
      isMenuItem: true,
      menuOrder: 2,
    },
    children: [
      {
        path: "meetings",
        name: "overviewMeetings",
        component: () => import("pages/overview/OverviewMeetings.vue"),
        meta: {
          title: `${mappedTerms().student[0].toUpperCase()}${mappedTerms().student.slice(
            1,
          )}bespreking`,
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
          settingRequired: ["studentMeeting"],
        },
      },
      {
        path: "group-meetings",
        name: "overviewGroupMeetings",
        component: () => import("pages/overview/OverviewGroupMeetings.vue"),
        meta: {
          title: "Groepsbespreking",
          isSubmenuItem: true,
          settingRequired: ["groupMeeting", "studentMeeting"],
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
        },
      },
      {
        path: "learning-objectives",
        name: "overviewLearningObjectives",
        component: () =>
          import("pages/overview/OverviewLearningObjectives.vue"),
        meta: {
          title: "Leerdoelen",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
          settingRequired: ["learningObjectives"],
        },
      },
      {
        path: "all-meetings",
        name: "overviewAllMeetings",
        component: () => import("pages/overview/OverviewAllMeetings.vue"),
        meta: {
          title: "Besprekingenoverzicht",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager", "organizer", "teamleader"],
          settingRequired: ["studentMeeting"],
        },
      },
    ],
  },
];

export default routes;
