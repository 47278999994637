import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { useSettingsStore } from "store/modules/settingStore.js";
import { computed, ref } from "vue";
import useHelpers from "composables/useHelpers.js";

/**
 * Module Store
 *
 * This store manages the state and actions related to modules.
 */
export const useModuleStore = defineStore("module", () => {
  const settingStore = useSettingsStore();
  const { schoolSettings, gradesEnabled } = storeToRefs(settingStore);
  const { checkRole } = useHelpers();

  /**
   * List of all available modules.
   * @type {import('vue').Ref<Array<string>>}
   */
  const allModules = ref([
    {
      name: "studentMeeting",
      indexRoute: checkRole("student") ? "MyRatings" : "myMeetingsIndex",
      myLessongroupsRoute: "myLessongroupsStudentMeetings",
      myStudentsRoute: "myStudentsStudentMeetings",
      overviewRoute: "overviewMeetings",
      manageRoute:
        checkRole("webmaster") || checkRole("manager") || checkRole("organizer")
          ? "manageMeetings"
          : // To manageLearningObjectives because if the role is other than the above and
            // they see the manage option it means they are using the learning objectives
            "manageLearningObjectives",
    },
    {
      name: "learningObjectives",
      indexRoute: checkRole("student")
        ? "myLearningObjectives"
        : "myLessongroupsLearningObjectives",
      myLessongroupsRoute: "myLessongroupsLearningObjectives",
      myStudentsRoute: "myStudentsLearningObjectives",
      overviewRoute: "overviewLearningObjectives",
      manageRoute: "manageLearningObjectives",
    },
    {
      name: "portfolio",
      indexRoute: checkRole("student")
        ? "portfolio"
        : "myLessongroupsPortfolio",
      myLessongroupsRoute: "myLessongroupsPortfolio",
      myStudentsRoute: "myStudentsPortfolio",
      overviewRoute: "overviewPortfolio",
      manageRoute: "manageSettingsGeneral",
    },
  ]);

  /**
   * Checks if a given module has its setting enabled.
   * @param {string} module - The name of the module.
   * @returns {boolean} True if the module setting is enabled, false otherwise.
   */
  const hasSettingEnabled = (module) => {
    const localstorageSchoolSettings = JSON.parse(
      localStorage.getItem("school_settings"),
    );

    switch (module) {
      case "transitionMeeting":
        // Always available for all schools when the student meeting setting is enabled
        return (
          schoolSettings?.value?.student_meeting_settings?.enabled ||
          localstorageSchoolSettings?.student_meeting_settings?.enabled
        );
      case "groupMeeting":
        return (
          schoolSettings?.value?.student_meeting_settings?.enabled ||
          localstorageSchoolSettings?.student_meeting_settings?.enabled
        );
      case "studentMeeting":
        return (
          schoolSettings?.value?.student_meeting_settings?.enabled ||
          localstorageSchoolSettings?.student_meeting_settings?.enabled
        );
      case "portfolio":
        return (
          schoolSettings?.value?.portfolio_settings?.enabled ||
          localstorageSchoolSettings?.portfolio_settings?.enabled
        );
      case "learningObjectives":
        return (
          schoolSettings?.value?.learning_objective_settings?.enabled ||
          localstorageSchoolSettings?.learning_objective_settings?.enabled
        );
      case "manageData":
        return (
          !schoolSettings?.value?.school_settings?.manage_data ||
          !localstorageSchoolSettings?.school_settings?.manage_data
        );
      case "gradesEnabled":
        return gradesEnabled.value;
    }
  };

  /**
   * Maps a module name to its display name.
   * @param {string} module - The name of the module.
   * @returns {string} The display name of the module.
   */
  const mapModuleName = (module) => {
    switch (module.name) {
      case "studentMeeting":
        return "leerlingbespreking";
      case "portfolio":
        return "portfolio";
      case "learningObjectives":
        return "leerdoelen";
    }
  };

  /**
   * Computes the list of active modules based on their settings.
   * @returns {Array<string>} The list of active modules.
   */
  const activeModules = computed(() => {
    // Use the Array.filter() method to filter out the modules that are not enabled
    const enabledModules = allModules.value.filter((module) =>
      hasSettingEnabled(module.name),
    );

    return enabledModules;
  });

  // Redirects to correct module based on the order of the allModules above.
  const redirectToCorrectModule = computed(() => {
    for (const module of allModules.value) {
      if (hasSettingEnabled(module.name)) {
        return module;
      }
    }
    return null; // or any default value if no module is enabled
  });

  return {
    hasSettingEnabled,
    activeModules,
    mapModuleName,
    redirectToCorrectModule,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModuleStore, import.meta.hot));
}
