const routes = [
  {
    path: "/tasks",
    name: "taskIndex",
    component: () => import("views/EmptyView.vue"),
    meta: {
      title: { general: "Taken", student: "Mijn taken" },
      isMenuItem: true,
      menuOrder: 12,
      settingRequired: ["portfolio"],
      icon: "fa-list-check",
    },
    redirect: {
      name: "tasks",
    },
    children: [
      {
        path: "",
        name: "tasks",
        component: () => import("pages/tasks/Tasks.vue"),
        meta: {
          title: "Ingeplande taken",
          isSubmenuItem: true,
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
            "student",
          ],
          settingRequired: ["portfolio"],
        },
        children: [
          {
            path: "add",
            name: "addTask",
            component: () => import("pages/portfolio/AddTask.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
              settingRequired: ["portfolio"],
            },
          },
        ],
      },
      {
        path: ":taskId",
        name: "task",
        component: () => import("pages/tasks/Task.vue"),
        meta: {
          title: "Taak",
          roleRequired: [
            "webmaster",
            "manager",
            "decaan",
            "organizer",
            "teamleader",
            "student",
            "teacher",
          ],
          settingRequired: ["portfolio"],
        },
        children: [
          {
            path: "edit",
            name: "editTask",
            component: () => import("pages/portfolio/EditTask.vue"),
            meta: {
              title: "Taak bewerken",
              roleRequired: [
                "webmaster",
                "manager",
                "decaan",
                "organizer",
                "teamleader",
                "teacher",
              ],
              settingRequired: ["portfolio"],
            },
          },
          {
            path: "add-activity",
            name: "addActivity",
            component: () => import("pages/portfolio/AddActivity.vue"),
            meta: {
              title: "Activiteit toevoegen",
              roleRequired: [
                "webmaster",
                "manager",
                "decaan",
                "organizer",
                "teamleader",
                "teacher",
                "student",
              ],
              settingRequired: ["portfolio"],
            },
          },
          {
            path: "edit-activity/:activityId",
            name: "editActivity",
            component: () => import("pages/portfolio/EditActivity.vue"),
            meta: {
              title: "Activiteit toevoegen",
              roleRequired: [
                "webmaster",
                "manager",
                "decaan",
                "organizer",
                "teamleader",
                "teacher",
                "student",
              ],
              settingRequired: ["portfolio"],
            },
          },
          {
            name: "taskSubActivities",
            path: ":activityId/subactivities",
            component: () => import("pages/portfolio/SubActivities.vue"),
          },
          {
            name: "taskActivityChat",
            path: ":activityId/chat",
            component: () => import("pages/portfolio/Chat.vue"),
          },
        ],
      },
      {
        path: "library",
        name: "tasksLibrary",
        redirect: {
          name: "tasksLibraryIndex",
        },
        component: () => import("views/EmptyView.vue"),
        meta: {
          title: "Takenbibliotheek",
          isSubmenuItem: true,
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
          settingRequired: ["portfolio"],
        },
        children: [
          {
            path: "",
            name: "tasksLibraryIndex",
            component: () => import("pages/tasks/ManageTemplateTasks.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
              settingRequired: ["portfolio"],
            },
            children: [
              {
                path: "add",
                name: "addTemplateTask",
                component: () => import("pages/portfolio/AddTemplateTask.vue"),
                meta: {
                  roleRequired: [
                    "webmaster",
                    "manager",
                    "organizer",
                    "teamleader",
                    "decaan",
                    "teacher",
                  ],
                  settingRequired: ["portfolio"],
                },
              },
            ],
          },
          {
            path: ":taskId",
            name: "templateTask",
            component: () => import("pages/tasks/TemplateTask.vue"),
            meta: {
              title: "Taak ",
              roleRequired: [
                "webmaster",
                "manager",
                "decaan",
                "organizer",
                "teamleader",
                "student",
                "teacher",
              ],
              settingRequired: ["portfolio"],
            },
            children: [
              {
                path: "edit",
                name: "templateTaskEdit",
                component: () => import("pages/portfolio/AddTemplateTask.vue"),
                meta: {
                  title: "Taak bewerken ",
                  roleRequired: [
                    "webmaster",
                    "manager",
                    "decaan",
                    "organizer",
                    "teamleader",
                    "student",
                    "teacher",
                  ],
                  settingRequired: ["portfolio"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () => import("pages/student/Portfolio.vue"),
    meta: {
      title: "Mijn portfolio",
      roleRequired: ["student"],
      isMenuItem: true,
      menuOrder: 9,
      settingRequired: ["portfolio"],
      icon: "fa-briefcase",
    },
    children: [
      {
        name: "portfolioAddActivity",
        path: "add-activity",
        component: () => import("pages/portfolio/AddActivity.vue"),
      },
      {
        name: "portfolioEditActivity",
        path: ":activityId/edit",
        component: () => import("pages/portfolio/AddActivity.vue"),
      },
      {
        name: "portfolioChat",
        path: ":activityId/chat",
        component: () => import("pages/portfolio/Chat.vue"),
      },
      {
        name: "portfolioSubActivities",
        path: ":activityId/subactivities",
        component: () => import("pages/portfolio/SubActivities.vue"),
      },
      {
        name: "portfolioEditInfo",
        path: "edit-info",
        component: () => import("pages/portfolio/EditInfo.vue"),
      },
    ],
  },
];

export default routes;
