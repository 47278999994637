// Export each validation rule individually
export const required = (value) => !!value || "Dit veld is verplicht";

export const email = (value) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Ongeldig e-mailadres";

export const min = (min) => (value) => {
  if (Array.isArray(value)) {
    return (
      value.length >= min ||
      `Selecteer minimaal ${min} item${min > 1 ? "s" : ""}`
    );
  }

  return value.length >= min || `Minimaal ${min} karakters`;
};

export const max = (max) => (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (Array.isArray(value)) {
    return (
      value.length <= max ||
      `Selecteer maximaal ${max} item${max > 1 ? "s" : ""}`
    );
  }

  return value.length <= max || `Maximaal ${max} karakters`;
};

export const url = (value) =>
  /^(https?:\/\/)/.test(value) ||
  "Ongeldige URL, moet beginnen met http:// of https://";

// Default export for backward compatibility
export default {
  required,
  email,
  min,
  max,
  url,
};
